import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { bindActionCreators } from "redux";
import { useMutation } from "@apollo/client";
import "react-medium-image-zoom/dist/styles.css";

import Layout from "../../components/Layout";
import "../../components/sections/checkout.scss";
import { ShoppingCart } from "../../components/ShoppingCart";
import AuthModal from "../../components/AuthModal";
import {
  ADD_USER_DETAILS,
  COURSE_AND_PLAN_ITEM,
  LMS_PLATFORM_OUT_LINK,
  LOGIN,
  SITE_NAME,
} from "../../utils/localization";
import { isFunction } from "lodash";
import { GET_USER_DETAILS } from "../../../graphql/queries/user";
import { initializeApollo } from "../../../apollo-client";
import { variants } from "../../utils/variants";
import { logger } from "../../helpers/logger";
import {
  cartDetailsActions,
  helperActions,
  userActions,
} from "../../utils/redux/actions";
import {
  CREATE_RAZORPAY_ORDER,
  VERIFY_PAYMENT,
  CREATE_PLAN_ORDER,
  REMOVE_CART_ITEM,
} from "../../../graphql/mutations/cart";
import { GET_LOCATION_LIST } from "../../../graphql/queries/location";
import { getFormattedLocationList } from "../../helpers/auth";
import {
  checkUserAddedPlanToCart,
  checkUserHasFreePlan,
} from "../../utils/helpers/component";
import { isSSR } from "../../helpers/global";

const Checkout = () => {
  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [isPlanOrderSuccess, setIsPlanOrderSuccess] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const cartDetails = useSelector((state) => state.cartDetails);
  const user = useSelector((state) => state.user);
  const userPlans = useSelector((state) => state.userPlans.plans);
  const [checkingUserDetailsStatus, setCheckingUserDetailsStatus] =
    useState(false);
  const [initialAuthState, setInitialAuthState] = useState(LOGIN);
  const dispatch = useDispatch();
  const { userUpdateAction } = bindActionCreators(userActions, dispatch);
  const { openToastMessage } = bindActionCreators(helperActions, dispatch);
  const { resetCartDetails } = bindActionCreators(cartDetailsActions, dispatch);
  const [createRazorPayOrder] = useMutation(CREATE_RAZORPAY_ORDER);
  const [createPlan] = useMutation(CREATE_PLAN_ORDER);
  const [verifyRazorPayPayment] = useMutation(VERIFY_PAYMENT);
  const [checkedOutCartDetails, setCheckedOutCartDetails] = useState(null);
  const [removeItemFromCart] = useMutation(REMOVE_CART_ITEM);
  const { updateCartDetails } = bindActionCreators(
    cartDetailsActions,
    dispatch
  );

  const [cbInstance, setCbInstance] = useState(
    isSSR()
      ? null
      : window?.Chargebee?.init({
          site: SITE_NAME,
        })
  );
  const isUserSignedIn = user?.isUserLoggedIn;

  const handleRemoveFromCart = async (uuId) => {
    try {
      const { data } = await removeItemFromCart({
        variables: { cartItemUuid: uuId },
      });

      if (data?.cartItemRemove) {
        updateCartDetails(data?.cartItemRemove);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) openToastMessage({ variant: variants.error });
    }
  };

  const handleRazorPayCheckout = (prefill, amountDue, id) => {
    let paymentOptions = {
      key: process.env.GATSBY_RAZORPAY_API_KEY,
      name: "Unschool",
      currency: "INR",
      order_id: id,
      amount: amountDue,
      handler: async function (response) {
        if (
          response?.razorpay_payment_id &&
          response?.razorpay_order_id &&
          response?.razorpay_signature
        ) {
          try {
            const { data } = await verifyRazorPayPayment({
              variables: {
                cartUuid: cartDetails?.uuid,
                rzpOrderId: id,
              },
            });
            if (data?.razorpayVerifyPayment) {
              setCheckedOutCartDetails(data.razorpayVerifyPayment);
              setIsPaymentSuccess(true);
              resetCartDetails();
              setTimeout(() => {
                window.open(LMS_PLATFORM_OUT_LINK);
              }, 1000);
            }
          } catch (error) {
            const gqlError = error.graphQLErrors[0];
            if (gqlError) {
              logger.warn(gqlError);
            }
          }
        }
      },
      prefill,
      theme: {
        color: "#F37254",
      },
    };
    let rzp1 = new window.Razorpay(paymentOptions);
    rzp1.open();
  };

  const handleCreateRazorPayOrder = async (prefill) => {
    try {
      const { data } = await createRazorPayOrder({
        variables: {
          cartUuid: cartDetails?.uuid,
        },
      });
      if (data?.razorpayOrderCreate) {
        const { amountDue, id } = data.razorpayOrderCreate;
        handleRazorPayCheckout(prefill, amountDue, id);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        openToastMessage({
          variant: variants.error,
        });
      }
    }
  };

  const checkOutCallback = async (
    firstName = null,
    lastName = null,
    email = null,
    mobileNumber = null
  ) => {
    handleCreateRazorPayOrder({
      name: `${firstName} ${lastName}`,
      email,
      contact: mobileNumber,
    });
  };

  const freeTrialCallback = async (
    firstName = null,
    lastName = null,
    email = null,
    mobileNumber = null,
    offlinePayment = true,
    chargebeeName
  ) => {
    try {
      const { data } = await createPlan({
        variables: {
          input: {
            start: true,
            cartUuid: cartDetails?.uuid,
            offlinePayment,
            chargebeeName,
          },
        },
      });
      if (data.startPlan) {
        setIsPlanOrderSuccess(true);
        resetCartDetails();
        setTimeout(() => {
          window.open(LMS_PLATFORM_OUT_LINK);
        }, 1000);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        console.log(error);
        logger.warn(gqlError);
      }
    }
  };

  const planCallback = async (
    firstName = null,
    lastName = null,
    email = null,
    mobileNumber = null
  ) => {
    const planItem = cartDetails?.cartItems[0];
    cbInstance.openCheckout({
      hostedPage: async () => {
        const response = await axios.get(
          `${process.env.GATSBY_CHARGEBEE_API_ENDPOINT}/generate_checkout_new_url?plan_id=${planItem?.itemable?.chargebeeName}&chargebee_id=${user?.userDetails?.getChargebeeId}`
        );
        return response.data;
      },
      success(hostedPageId) {
        handleRemoveFromCart(planItem.uuid);
        setIsPlanOrderSuccess(true);
        setTimeout(() => {
          window.open(LMS_PLATFORM_OUT_LINK);
        }, 1000);
      },
      close: () => {
        console.log("checkout new closed");
      },
      step(step) {
        console.log("checkout", step);
      },
    });
  };

  const checkIfUserFilledData = async (callback) => {
    const apolloClient = initializeApollo({
      initialState: null,
      ctx: null,
    });
    try {
      const { data: userData } = await apolloClient.query({
        query: GET_USER_DETAILS,
        fetchPolicy: "network-only",
      });
      if (userData?.currentUser) {
        try {
          const { data: locationListData } = await apolloClient.query({
            query: GET_LOCATION_LIST,
          });
          if (locationListData?.LocationList) {
            setCheckingUserDetailsStatus(false);
            const {
              email,
              firstName,
              lastName,
              mobileNumber,
              college,
              profilePicture,
              location,
            } = userData.currentUser;
            if (!firstName || !lastName || !mobileNumber || !location) {
              setInitialAuthState(ADD_USER_DETAILS);
              setOpenAuthModal(true);
              userUpdateAction({
                locationList: getFormattedLocationList(
                  locationListData?.LocationList[0]?.children
                ),
                firstName,
                lastName,
                mobileNumber,
                location,
                college,
                profilePicture,
              });
            }
            if (firstName && lastName && mobileNumber && location) {
              userUpdateAction({
                firstName,
                lastName,
                mobileNumber,
                college,
                profilePicture,
                location,
                locationList: getFormattedLocationList(
                  locationListData?.LocationList[0]?.children
                ),
              });

              callback(firstName, lastName, email, mobileNumber);
            }
          }
        } catch (error) {
          setCheckingUserDetailsStatus(false);
          const gqlError = error.graphQLErrors[0];
          if (gqlError) {
            logger.print(gqlError.message);
          }
        }
      }
    } catch (error) {
      setCheckingUserDetailsStatus(false);
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        openToastMessage({
          variant: variants.error,
        });
      }
    }
  };

  const handleCloseModal = (callback = () => {}) => {
    setOpenAuthModal(false);
    isFunction(callback) && callback();
  };

  const onCheckout = () => {
    setCheckingUserDetailsStatus(true);
    if (isUserSignedIn) {
      checkIfUserFilledData(checkOutCallback);
    } else {
      setCheckingUserDetailsStatus(false);
      setOpenAuthModal(true);
    }
  };

  const onSubscribeFreeTrail = (offlinePayment, chargebeeName) => {
    setCheckingUserDetailsStatus(true);
    if (isUserSignedIn) {
      checkIfUserFilledData(() => {
        freeTrialCallback(
          null,
          null,
          null,
          null,
          offlinePayment,
          chargebeeName
        );
      });
    } else {
      setCheckingUserDetailsStatus(false);
      setOpenAuthModal(true);
    }
  };

  const onSubscribePlan = () => {
    setCheckingUserDetailsStatus(true);
    if (isUserSignedIn) {
      checkIfUserFilledData(planCallback);
    } else {
      setCheckingUserDetailsStatus(false);
      setOpenAuthModal(true);
    }
  };

  const toastPlanAndCourse = () => {
    openToastMessage({
      variant: variants.warningToastMessage,
      title: "You Must Remove Individual Courses Or Series To Buy Plan",
    });
  };

  useEffect(() => {
    if (checkUserAddedPlanToCart(cartDetails) === COURSE_AND_PLAN_ITEM) {
      openToastMessage({
        variant: variants.warningToastMessage,
        title: "You Must Remove Individual Courses Or Series To Buy Plan",
      });
    }
  }, [cartDetails]);

  return (
    // NOTE: This condition should accept details shown when checkedOutCardDetails is not null ( i.e. payment success)
    <Layout footer={true}>
      {!isSSR() && (
        <>
          <script src="https://js.chargebee.com/v2/chargebee.js" />
          <script
            async
            src="https://checkout.razorpay.com/v1/checkout.js"
            key={1}
          />
        </>
      )}
      <div className="payment-index">
        <ShoppingCart
          onCheckout={onCheckout}
          onSubscribePlan={onSubscribePlan}
          onSubscribeFreeTrail={onSubscribeFreeTrail}
          toastPlanAndCourse={toastPlanAndCourse}
          checkingUserDetailsStatus={checkingUserDetailsStatus}
          isPaymentSuccess={isPaymentSuccess}
          isPlanOrderSuccess={isPlanOrderSuccess}
          checkedOutCartDetails={checkedOutCartDetails}
          hasFreePlan={checkUserHasFreePlan(userPlans)}
          checkUserAddedPlanToCart={checkUserAddedPlanToCart(cartDetails)}
          userPlans={userPlans}
        />
      </div>
      <AuthModal
        key={initialAuthState} // We are doing this to make sure this Modal re-renders on every change in initialAuthState
        openModal={openAuthModal}
        initialAuthState={initialAuthState}
        handleCloseModal={handleCloseModal}
      />
    </Layout>
  );
};

export function Head() {
  return (
    <>
      <script src="https://js.chargebee.com/v2/chargebee.js" />
      <script
        async
        src="https://checkout.razorpay.com/v1/checkout.js"
        key={1}
      />
    </>
  );
}

export default Checkout;
