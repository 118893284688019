import React from "react";
import { fas } from "../../../utils/icons";
import { Col, Select } from "antd";
import classNames from "classnames";

import * as classes from "./CustomDropDown.module.scss";
import { CustomIcon } from "../CustomIcon";

const { Option } = Select;

export const CustomDropDown = (props) => {
  const { options, onChange, customClass, defaultValue } = props;

  return (
    <Col className={classes.customSelectWrapper}>
      <Select
        className={classNames(classes.customSelectBox, customClass)}
        dropdownClassName={classes.selectDropdown}
        suffixIcon={<CustomIcon icon={fas.faChevronDown} />}
        getPopupContainer={(trigger) => trigger.parentElement}
        defaultValue={defaultValue}
        onChange={onChange}
      >
        {options.map((option, i) => (
          <Option value={option.value} key={`option-index-${option.value}`}>
            {option.lable}
          </Option>
        ))}
      </Select>
    </Col>
  );
};
