import React, { Fragment } from "react";
import Img from "gatsby-image";
import "./sections/billDetailsCard.scss";
import { CustomButton } from "../components/adaptors/CustomButton";
import { variants } from "../utils/variants";
import { fas } from "../utils/icons";
import { Col, Typography, Form, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import Voucher from "../img/images/icons/voucher.svg";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@apollo/client";
import { APPLY_COUPON, REMOVE_COUPON } from "../../graphql/mutations/cart";
import CustomInput from "./adaptors/CustomInput";
import { cartDetailsActions } from "../utils/redux/actions";
import { gqlErrors } from "../../graphql/errors";
import FormErrorMessage from "./FormErrorMessage";
import { logger } from "../helpers/logger";
import {
  COURSE_AND_PLAN_ITEM,
  COURSE_ITEM,
  PLAN_ITEM,
} from "../utils/localization";

const { Title, Text } = Typography;

export const BillDetailsCard = (props) => {
  const {
    subtotal,
    discount,
    couponName,
    checkoutAction,
    checkingUserDetailsStatus,
    isPaymentSuccess,
    cartUuId,
    coupon,
    gst,
    total,
    checkUserAddedPlanToCart,
    toastPlanAndCourse,
    onSubscribePlan,
    onSubscribeFreeTrail,
    hasFreePlan,
    userPlans,
  } = props;

  const [form] = useForm();
  const { setFields } = form;
  const dispatch = useDispatch();
  const [applyCoupon] = useMutation(APPLY_COUPON);
  const [removeCoupon] = useMutation(REMOVE_COUPON);
  const { updateCartDetails } = bindActionCreators(
    cartDetailsActions,
    dispatch
  );

  const { cartDetails } = useSelector((state) => state);

  const handleRemoveCoupon = async () => {
    try {
      const { data } = await removeCoupon({
        variables: { cartUuid: cartUuId },
      });
      if (data?.couponRemove) {
        updateCartDetails(data?.couponRemove);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        logger.warn(gqlError);
      }
    }
  };

  const handleApplyCoupon = async (coupon) => {
    try {
      const { data } = await applyCoupon({
        variables: { couponCode: coupon, cartUuid: cartUuId },
      });
      if (data?.couponApply) {
        const couponAppliedCartDetails = data.couponApply;
        updateCartDetails(couponAppliedCartDetails);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      const gqlError = error.graphQLErrors[0];
      if (
        gqlError?.message === gqlErrors.messages.couponCode ||
        gqlErrors.messages.couponCodeAlreadyPresent
      ) {
        setFields([
          {
            name: "coupon",
            errors: [
              <FormErrorMessage
                hideFormHelperIcon
                key="coupon-error-message"
                type="coupon"
              />,
            ],
          },
        ]);
      } else {
        logger.print(gqlError);
      }
    }
  };

  const onFinish = (values) => {
    let { coupon } = values;
    handleApplyCoupon(coupon);
  };

  const renderButton = (itemsType) => {
    switch (itemsType) {
      case COURSE_AND_PLAN_ITEM:
        return (
          <Fragment>
            <CustomButton
              title="CHECKOUT"
              variant={variants.primaryButton}
              customClass="checkoutButton"
              customTextClass="checkoutButtonTextClass"
              fitContent
              onClick={toastPlanAndCourse}
              disabled={checkingUserDetailsStatus}
              loading={checkingUserDetailsStatus}
            />
          </Fragment>
        );
      case PLAN_ITEM:
        return (
          <>
            <CustomButton
              title="SUBSCRIBE"
              variant={variants.primaryButton}
              customClass="checkoutButton"
              customTextClass="checkoutButtonTextClass"
              fitContent
              onClick={() => {
                const plan = cartDetails?.cartItems[0];
                if (!plan?.itemable?.chargebeeName) {
                  onSubscribeFreeTrail(false, null);
                } else if (
                  plan?.itemable?.chargebeeName &&
                  cartDetails?.totalAmount === 0
                ) {
                  onSubscribeFreeTrail(true, plan?.itemable?.chargebeeName);
                } else {
                  onSubscribePlan();
                }
              }}
              disabled={checkingUserDetailsStatus || userPlans.length <= 0}
              loading={checkingUserDetailsStatus}
            />
          </>
        );
      case COURSE_ITEM:
        return (
          <>
            <CustomButton
              title="CHECKOUT"
              variant={variants.primaryButton}
              customClass="checkoutButton"
              customTextClass="checkoutButtonTextClass"
              fitContent
              onClick={checkoutAction}
              disabled={checkingUserDetailsStatus}
              loading={checkingUserDetailsStatus}
            />
          </>
        );
      default:
        break;
    }
  };

  return (
    <Col className="billCardContainer">
      <Title className="billTitle">Bill Details</Title>
      <Col className="billItem">
        <Text className="billContent">Subtotal</Text>
        <Text className="billAmount">₹{subtotal}</Text>
      </Col>
      <Col className="billItem">
        <Text className="billContent">Total Discount</Text>
        <Text className="billAmount">-₹{discount}</Text>
      </Col>
      <Col className="billItem">
        <Text className="billContent">GST 18%</Text>
        <Text className="billAmount">₹{gst}</Text>
      </Col>
      <Col className="billTotal">
        <Text className="totalContent">Items total</Text>
        <Text className="totalAmount">₹{total}</Text>
      </Col>
      {!isPaymentSuccess && (
        <>
          {renderButton(checkUserAddedPlanToCart)}
          {coupon?.code ? (
            <Col className="couponDetailsContainer">
              <Img fluid={{ src: Voucher }} className="couponIcon" />
              <Text className="couponName">{couponName}</Text>
              <Text className="couponText">applied</Text>
              <CustomButton
                iconButton
                size="small"
                variant={variants.ghostButton}
                icon={fas.faTrash}
                onClick={() => handleRemoveCoupon()}
              />
            </Col>
          ) : (
            <Form
              form={form}
              name="basic"
              layout="inline"
              onFinish={onFinish}
              autoComplete="off"
              className="coupon-form"
            >
              <Form.Item
                className="form-item-left"
                name="coupon"
                rules={[
                  {
                    required: true,
                    message: <FormErrorMessage hideFormHelperIcon />,
                  },
                ]}
              >
                <CustomInput
                  formItem
                  variant={variants.textInput}
                  placeholder="Coupon code"
                  customClass="coupon-input"
                />
              </Form.Item>
              <Form.Item className="form-item-right">
                <CustomButton
                  customClass="apply-btn"
                  variant={variants.ghostButton}
                  htmlType="submit"
                  title="Apply"
                />
              </Form.Item>
            </Form>
          )}
        </>
      )}
    </Col>
  );
};
