import React from "react";
import SuccessLogo from "../img/images/creative-tick.svg";
import { LMS_PLATFORM_OUT_LINK } from "../utils/localization";
import "./sections/successDetails.scss";

export const PaymentSuccessDetails = () => (
  <div className="success-details">
    <div className="success-details-inner">
      <img src={SuccessLogo} alt="Success Logo" />
      <h3>Order Successful!</h3>
      <p>
        Thank you for signing up with Unschool. You can access your courses
        through the learning portal.
      </p>
      <a href={LMS_PLATFORM_OUT_LINK}>
        <button className="portal-btn">GO TO LEARNING PORTAL</button>
      </a>
    </div>
  </div>
);

export default PaymentSuccessDetails;
