import React from "react";
import Img from "gatsby-image";
import { Col } from "antd";

import "./sections/selectedCourseDetails.scss";
import SeriesCardTag from "../img/images/series-card-tag.svg";
import {
  HOME_LANDING_PAGE_FILES_PATH,
  IMAGE_PLACEHOLDER,
} from "../utils/localization";

export default function SelectedCourseDetails(props) {
  const {
    courseImg,
    name,
    discountedTotal,
    total,
    discount,
    typeName,
    cartItem,
  } = props;

  return (
    <Col className="container">
      <Col className="select-course-detail">
        <Col className="course-img-wrapper">
          {typeName == "Series" && (
            <img
              src={SeriesCardTag ?? IMAGE_PLACEHOLDER}
              className="series-card-tag"
              alt="series tag"
            />
          )}
          <Img
            loading="eager"
            fluid={{ src: courseImg || IMAGE_PLACEHOLDER }}
            className="course-profile-img"
            alt="Course profile image"
          />
        </Col>
        <Col className="course-info">
          <p className="course-name">{name}</p>
          {discount === 0 ? (
            <p className="course-price">₹{total}</p>
          ) : (
            <p className="course-price">
              ₹{discountedTotal}
              {total !== discountedTotal ? (
                <span className="total">₹{total}</span>
              ) : null}
            </p>
          )}
        </Col>
      </Col>
      {cartItem?.nsdcIncluded && (
        <Col className="select-course-detail">
          <Col className="course-img-wrapper">
            <Img
              loading="eager"
              fluid={{
                src: `${HOME_LANDING_PAGE_FILES_PATH}/nsdc-certificate.png`,
              }}
              className="course-profile-img"
              alt="Course profile image"
            />
          </Col>
          <Col className="course-info">
            <p className="course-name">NSDC Certificate</p>
            <p className="course-price">₹{cartItem?.nsdcPrice}</p>
          </Col>
        </Col>
      )}
    </Col>
  );
}
