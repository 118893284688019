import { Col, Typography } from "antd";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { CourseCardBig } from "../components/CourseCardBig";
import { BillDetailsCard } from "../components/BillDetailsCard";
import PaymentSuccessDetails from "./PaymentSuccessDetails";
import SelectedCourseDetails from "./SelectedCourseDetails";
import { EmptyCart } from "./EmptyCart";
import "./sections/shoppingCart.scss";

export const ShoppingCart = ({
  onCheckout,
  checkingUserDetailsStatus,
  isPaymentSuccess,
  checkedOutCartDetails,
  isPlanOrderSuccess,
  hasFreePlan,
  userPlans,
  checkUserAddedPlanToCart,
  toastPlanAndCourse,
  onSubscribePlan,
  onSubscribeFreeTrail,
}) => {
  let cartDetails = useSelector((state) => state.cartDetails);

  if (isPaymentSuccess) {
    cartDetails = checkedOutCartDetails;
  }

  const { Title } = Typography;

  const cartItems = isPaymentSuccess
    ? checkedOutCartDetails?.cartItems
    : cartDetails?.cartItems;

  const getPaymentScreen = () => {
    if (isPlanOrderSuccess) {
      return (
        <Col className="cartContainer">
          <PaymentSuccessDetails />
        </Col>
      );
    }
    if (isPaymentSuccess) {
      return (
        <Col className="cartContainer">
          <Col>
            <Col className="cartSection">
              <PaymentSuccessDetails />
              <Col>
                <Col>
                  <BillDetailsCard
                    coupon={cartDetails?.coupon}
                    cartUuId={cartDetails?.uuid}
                    isPaymentSuccess={isPaymentSuccess}
                    subtotal={(cartDetails?.subTotalAmount + cartDetails?.nsdcAmount).toFixed(2)}
                    discount={cartDetails?.discountAmount}
                    gst={cartDetails?.taxAmount}
                    total={cartDetails?.totalAmount}
                    couponName={cartDetails?.coupon?.code}
                    checkoutAction={onCheckout}
                    checkingUserDetailsStatus={checkingUserDetailsStatus}
                    checkUserAddedPlanToCart={checkUserAddedPlanToCart}
                    onSubscribePlan={onSubscribePlan}
                    toastPlanAndCourse={toastPlanAndCourse}
                    hasFreePlan={hasFreePlan}
                    onSubscribeFreeTrail={onSubscribeFreeTrail}
                    userPlans={userPlans}
                  />
                </Col>
                {!isEmpty(checkedOutCartDetails) && (
                  <Fragment>
                    <div className="items-count">
                      {cartItems?.length}&nbsp;
                      {cartItems?.length > 1 ? "items" : "item"}
                    </div>
                    <Col className="purchased-course-list">
                      {cartItems?.map((course, index) => (
                        <Col className="selected-course-list">
                          <Title>Item-{index + 1}</Title>
                          <SelectedCourseDetails
                            courseImg={course?.itemable?.coverImage?.url}
                            name={course?.itemable?.title}
                            discount={cartDetails?.discountAmount}
                            discountedTotal={course?.totalAmount}
                            total={course?.subTotalAmount}
                            typeName={course?.itemable?.__typename}
                            cartItem={course}
                          />
                        </Col>
                      ))}
                    </Col>
                  </Fragment>
                )}
              </Col>
            </Col>
          </Col>
        </Col>
      );
    }
    if (isEmpty(cartItems)) {
      return (
        <Col className="cartContainer">
          <Col>
            <Title className="cartTitle">Shopping Cart</Title>
          </Col>
          <Col>
            <EmptyCart />
          </Col>
        </Col>
      );
    }
    return (
      <Col className="cartContainer">
        <Col>
          <Title className="cartTitle">Shopping Cart</Title>
        </Col>
        <Col>
          <Col className="cartSection">
            <Col className="courseContainer">
              <p>
                {cartItems?.length}
                {cartItems?.length > 1 ? " items" : " item"}
              </p>
              {cartItems?.map((cartItem, index) => {
                return (
                  <Col key={cartItem?.uuid} className="courseCard">
                    <CourseCardBig
                      uuId={cartItem?.uuid}
                      cartUuId={cartDetails?.uuid}
                      course={cartItem?.itemable}
                      discountedTotal={cartItem?.totalAmount}
                      total={cartItem?.subTotalAmount}
                      discount={cartItem?.discountAmount}
                      typeName={cartItem?.itemable?.__typename}
                      hasFreePlan={hasFreePlan}
                      userPlans={userPlans}
                      cartItem={cartItem}
                      index={index}
                    />
                  </Col>
                );
              })}
            </Col>
            <Col>
              <BillDetailsCard
                coupon={cartDetails?.coupon}
                cartUuId={cartDetails?.uuid}
                isPaymentSuccess={isPaymentSuccess}
                subtotal={(cartDetails?.subTotalAmount + cartDetails?.nsdcAmount).toFixed(2)}
                discount={cartDetails?.discountAmount}
                gst={cartDetails?.taxAmount}
                total={cartDetails?.totalAmount}
                couponName={cartDetails?.coupon?.code}
                checkoutAction={onCheckout}
                checkingUserDetailsStatus={checkingUserDetailsStatus}
                checkUserAddedPlanToCart={checkUserAddedPlanToCart}
                onSubscribePlan={onSubscribePlan}
                toastPlanAndCourse={toastPlanAndCourse}
                hasFreePlan={hasFreePlan}
                onSubscribeFreeTrail={onSubscribeFreeTrail}
                userPlans={userPlans}
              />
            </Col>
          </Col>
        </Col>
      </Col>
    );
  };

  return <>{getPaymentScreen()}</>;
};
