import React from "react";
import { Col, Typography } from "antd";
import Img from "gatsby-image";
import { CustomButton } from "./adaptors/CustomButton";
import { EMPTY_CART_SVG_PATH } from "../utils/localization";
import { variants } from "../utils/variants";
import "./sections/emptyCart.scss";

const { Title, Paragraph } = Typography;

export const EmptyCart = () => {
  const handleGoToCourses = () => {
    window.location.href = "/";
  };

  return (
    <Col className="empty-cart-container">
      <Img
        fluid={{ src: EMPTY_CART_SVG_PATH }}
        className="empty-cart-img"
        alt="Empty cart"
      />
      <Title className="empty-title">Your cart is empty</Title>
      <Paragraph className="empty-description">
        We offer courses in Technology, Design, Management, Marketing Business
        and more.
      </Paragraph>
      <CustomButton
        title="EXPLORE COURSES"
        onClick={() => handleGoToCourses()}
        variant={variants.primaryButton}
        fitContent
      />
    </Col>
  );
};
