import React, { Fragment, useState, useMemo } from "react";
import Img from "gatsby-image";
import { Button, Col, Typography } from "antd";
import { CustomIcon } from "./adaptors/CustomIcon";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux";
import { useMutation } from "@apollo/client";
import classNames from "classnames";
import ProfileAvatar from "./ProfileAvatar";

import { fas } from "../utils/icons";
import "./sections/courseCardBig.scss";
import {
  REMOVE_CART_ITEM,
  ADD_CART_ITEM,
  ADD_OR_REMOVE_NSDC_CERTIFICATION_TO_COURSE,
} from "../../graphql/mutations/cart";
import { cartDetailsActions, helperActions } from "../utils/redux/actions";
import { variants } from "../utils/variants";
import SeriesCardTag from "../img/images/series-card-tag.svg";
import {
  IMAGE_PLACEHOLDER,
  SERIES_PATH,
  SKILL_COURSE_PATH,
  LOADING,
  HAS_FREE_PLAN,
  HAS_NO_FREE_PLAN,
  INTERNSHIP_PROGRAM_PATH,
  SENIOR_COACH,
} from "../utils/localization";
import { isMobile, isSSR } from "../helpers/global";
import { sortPlansAcend } from "../utils/helpers/component";
import { CustomDropDown } from "./adaptors/CustomDropDown/CustomDropDown";
import { CustomButton } from "./adaptors/CustomButton";
import { CustomModal } from "./CustomModal";
import NSDCModalContent from "./IntershipProgam1.0/NSDCModalContent";
import NSDCRemoveoPopUpModalContent from "./IntershipProgam1.0/NSDCRemoveoPopUpModalContent";

const { Title, Text } = Typography;

export const CourseCardBig = (props) => {
  const {
    course,
    discountedTotal,
    total,
    uuId,
    cartUuId,
    discount,
    typeName,
    userPlans,
    hasFreePlan,
    cartItem,
    index,
  } = props;
  const dispatch = useDispatch();
  const { updateCartDetails, setCartDetails } = bindActionCreators(
    cartDetailsActions,
    dispatch
  );
  const { openToastMessage } = bindActionCreators(helperActions, dispatch);
  const [removeItemFromCart] = useMutation(REMOVE_CART_ITEM);
  const [addItemToCart] = useMutation(ADD_CART_ITEM);
  const [updateNsdc] = useMutation(ADD_OR_REMOVE_NSDC_CERTIFICATION_TO_COURSE);
  const [nsdcModalOpen, setNsdcModalOpen] = useState(false);
  const [openNsdcRemovePopup, setNsdcRemovePopup] = useState(false);

  const handleRedirect = () => {
    if (!isSSR()) {
      switch (typeName) {
        case "Plan":
          return (window.location = `/${SKILL_COURSE_PATH}/`);
        case "Series":
          return (window.location = `/${SERIES_PATH}/` + course?.slug);
        default:
          return (window.location =
            `/${INTERNSHIP_PROGRAM_PATH}/` + course?.slug);
      }
    }
  };

  const handleRemoveFromCart = async () => {
    try {
      const { data } = await removeItemFromCart({
        variables: { cartItemUuid: uuId },
      });

      if (data?.cartItemRemove) {
        updateCartDetails(data?.cartItemRemove);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) openToastMessage({ variant: variants.error });
    }
  };

  const handleChangePlan = async (e) => {
    try {
      await removeItemFromCart({
        variables: { cartItemUuid: uuId },
      });

      const { data } = await addItemToCart({
        variables: cartUuId
          ? {
              itemableType: "plan",
              itemableId: e,
              cartUuid: cartUuId,
            }
          : {
              itemableType: "plan",
              itemableId: e,
            },
      });
      if (data?.cartItemAdd) {
        setCartDetails(data?.cartItemAdd);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) openToastMessage({ variant: variants.error });
    }
  };

  const renderPlan = () => {
    const monthsUpdatedPlans = sortPlansAcend([...userPlans]).map((plan) => {
      const planValidity = (parseInt(plan.validity) / 30).toFixed();
      if (plan.chargebeeName === null) {
        return {
          ...plan,
          lable: plan.title,
          value: plan.id,
        };
      }
      return {
        ...plan,
        lable: `${
          planValidity < 10 ? `0${planValidity}` : planValidity
        } Month Plan`,
        value: plan.id,
      };
    });
    const plansWithNoFreePlan = monthsUpdatedPlans.filter(
      (plan) => plan.chargebeeName !== null
    );
    switch (hasFreePlan) {
      case LOADING:
        return <Col className="plansDropDown">Loading ...</Col>;
      case HAS_FREE_PLAN:
        return (
          <Col className="plansDropDown">
            <CustomDropDown
              options={monthsUpdatedPlans}
              onChange={handleChangePlan}
              defaultValue={course.id}
            />
          </Col>
        );
      case HAS_NO_FREE_PLAN:
        return (
          <Col className="plansDropDown">
            <CustomDropDown
              options={plansWithNoFreePlan}
              onChange={handleChangePlan}
              defaultValue={course.id}
            />
          </Col>
        );
      default:
        break;
    }
  };

  const handleRemoveNsdc = async () => {
    setNsdcRemovePopup(true);
  };

  console.log(course);

  const handleAddNsdc = async () => {
    try {
      const { data } = await updateNsdc({
        variables: {
          input: { cartItemUuid: cartItem.uuid, nsdcIncluded: true },
        },
      });
      if (data?.cartNsdcUpdate) {
        updateCartDetails(data?.cartNsdcUpdate);
      }
    } catch (error) {
      const gqlError = error.graphQLErrors[0];
      if (gqlError) {
        openToastMessage({
          variant: variants.error,
        });
      }
    }
  };

  const coach = useMemo(
    () =>
      course?.coaches?.filter((coach) => coach?.userType === SENIOR_COACH)[0],
    []
  );

  const getCardDetails = (typeName) => {
    switch (typeName) {
      case "Course":
        return (
          <Col className="internship">
            <Col className="courseCardContainer">
              <Col className="courseDetailsContainer" onClick={handleRedirect}>
                <Col className="course-img-wrapper">
                  <Img
                    className="courseImage"
                    alt="course-icon"
                    fluid={{
                      src: course?.coverImage?.url ?? IMAGE_PLACEHOLDER,
                    }}
                  />
                  <Title className="coursePrice">
                    {course?.orignalPrice &&
                      !isNaN(course?.orignalPrice) &&
                      course?.orignalPrice - course?.price > 0 && (
                        <span>&nbsp;₹{course?.orignalPrice}&nbsp;</span>
                      )}
                    ₹{course.price}
                  </Title>
                </Col>
                <Col className="courseDetails">
                  <Col className="courseTitle" onClick={handleRedirect}>
                    {course?.title}
                  </Col>
                  <Col className="instructorProfile">
                    <ProfileAvatar
                      size={32}
                      customClass="instructorPhoto"
                      profilePicture={{
                        url: coach?.profilePicture?.url,
                      }}
                      firstName={coach?.firstName}
                      lastName={coach?.firstLast}
                      alt={"Image"}
                    />
                    <p>{coach?.firstName}</p>
                  </Col>
                </Col>
              </Col>
              {course?.nsdcCertified && <Col className="plusIcon">+</Col>}
              <Col className="nsdcAddOnAndPriceContainer">
                {course?.nsdcCertified && (
                  <Col className="addOn">
                    <Col className="addOnItem">
                      <Col className="left">
                        <Title>NSDC Certification</Title>
                        <Button
                          onClick={() => {
                            setNsdcModalOpen(true);
                          }}
                        >
                          Why This ?
                        </Button>
                      </Col>
                      {cartItem?.nsdcIncluded ? (
                        <Col className="right">
                          <Title>
                            {!isNaN(cartItem?.nsdcOrignalPriceCart) &&
                              cartItem?.nsdcOrignalPriceCart -
                                cartItem?.nsdcPrice >
                                0 && (
                                <span>
                                  &nbsp;₹{cartItem?.nsdcOrignalPriceCart}&nbsp;
                                </span>
                              )}
                            ₹{cartItem?.nsdcPrice}
                          </Title>
                          <Button onClick={handleRemoveNsdc}>
                            <CustomIcon
                              icon={fas.faTrash}
                              customClass="deleteIcon"
                            />
                          </Button>
                        </Col>
                      ) : (
                        <Col className="right">
                          <Title>
                            {!isNaN(cartItem?.nsdcOrignalPriceCart) &&
                              cartItem?.nsdcOrignalPriceCart -
                                cartItem?.nsdcPrice >
                                0 && (
                                <span>
                                  &nbsp;₹{cartItem?.nsdcOrignalPriceCart}&nbsp;
                                </span>
                              )}
                            {cartItem?.nsdcPrice}
                          </Title>
                          <CustomButton
                            variant={variants.primaryButton}
                            htmlType="button"
                            title="Add"
                            onClick={handleAddNsdc}
                            customClass="nsdcAddButtonClass"
                            customTextClass="nsdcAddButtonClassTextClass"
                          />
                        </Col>
                      )}
                    </Col>
                    <CustomModal
                      visible={nsdcModalOpen}
                      onCancel={() => {
                        setNsdcModalOpen(false);
                      }}
                      centered={true}
                      width="auto"
                      maskStyle={{ background: "rgba(0, 0, 0, 0.45)" }}
                      title="NSDC Certificate Details"
                      bodyStyle={{
                        padding: "0 20px 20px 20px",
                      }}
                    >
                      <NSDCModalContent />
                    </CustomModal>
                  </Col>
                )}
              </Col>
            </Col>
            <Col className={classNames("priceSection")}>
              Total Price:
              <Col className="priceItem">
                {discount === 0 ? (
                  <Col className="discountedTotal">₹{total}</Col>
                ) : (
                  <Fragment>
                    <Col className="discountedTotal">₹{discountedTotal}</Col>
                    {total !== discountedTotal ? (
                      <Col className="total">₹{total}</Col>
                    ) : null}
                  </Fragment>
                )}
              </Col>
            </Col>
          </Col>
        );
      case "Plan":
        return (
          <Col className="plan">
            <Col className="skillCourseCardContainer">
              <Col className="course-img-wrapper" onClick={handleRedirect}>
                <Img
                  className="courseImage"
                  alt="course-icon"
                  fluid={{ src: course?.coverImage?.url ?? IMAGE_PLACEHOLDER }}
                />
              </Col>
              <Col className="courseDetails">
                <Col className="courseTitle" onClick={handleRedirect}>
                  SKill Courses
                </Col>
                <Text>Validity : {course?.title}</Text>
              </Col>
              <Col className="desktopPlansRender">{renderPlan()}</Col>
            </Col>
            <Col className="mobilePlanRender">{renderPlan()}</Col>
            <Col className="priceSection">
              Total Price:
              <Col className="priceItem">
                {discount === 0 ? (
                  <Col className="discountedTotal">₹{total}</Col>
                ) : (
                  <Fragment>
                    <Col className="discountedTotal">₹{discountedTotal}</Col>
                    {total !== discountedTotal ? (
                      <Col className="total">₹{total}</Col>
                    ) : null}
                  </Fragment>
                )}
              </Col>
            </Col>
          </Col>
        );
      case "Series":
        return (
          <Col className="series">
            <Col className="courseCardContainer">
              <Col className="course-img-wrapper" onClick={handleRedirect}>
                <img
                  src={SeriesCardTag}
                  className="courseImage"
                  alt="series tag"
                />
              </Col>
              <Col className="courseDetails">
                <Col className="courseTitle" onClick={handleRedirect}>
                  {course?.title}
                </Col>
              </Col>
            </Col>
            <Col className="priceSection">
              Total Price:
              <Col className="priceItem">
                {discount === 0 ? (
                  <Col className="discountedTotal">₹{total}</Col>
                ) : (
                  <Fragment>
                    <Col className="discountedTotal">₹{discountedTotal}</Col>
                    {total !== discountedTotal ? (
                      <Col className="total">₹{total}</Col>
                    ) : null}
                  </Fragment>
                )}
              </Col>
            </Col>
          </Col>
        );
      default:
        break;
    }
  };

  return (
    <Col className="courseCardBig">
      <Col className="top">
        <Title>Item - {index + 1}</Title>
        <Col className="deleteButtonContainer">
          <Button
            className="deleteButton"
            onClick={() => handleRemoveFromCart()}
          >
            <CustomIcon icon={fas.faTrash} customClass="deleteIcon" />
          </Button>
        </Col>
      </Col>
      {getCardDetails(typeName)}
      <CustomModal
        visible={openNsdcRemovePopup}
        onCancel={() => {
          setNsdcRemovePopup(false);
        }}
        centered={true}
        width="auto"
        maskStyle={{ background: "rgba(0, 0, 0, 0.45)" }}
        title=""
        bodyStyle={
          isMobile()
            ? {
                padding: "60px 15px 15px 15px",
              }
            : {
                padding: "50px 60px 30px 30px",
              }
        }
      >
        <NSDCRemoveoPopUpModalContent
          cartItem={cartItem}
          onCancel={() => {
            setNsdcRemovePopup(false);
          }}
        />
      </CustomModal>
    </Col>
  );
};
