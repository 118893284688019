import { isEmpty } from "lodash";
import { isMobile } from "../../helpers/global";
import {
  LOADING,
  HAS_SUBSCRIBED_TO_PLAN,
  HAS_NOT_SUBSCRIBED_TO_PLAN,
  HAS_FREE_PLAN,
  HAS_NO_FREE_PLAN,
  COURSE_AND_PLAN_ITEM,
  PLAN_ITEM,
  COURSE_ITEM,
} from "../localization";

export const checkUserHasSubscribedToPlan = (userDetails) => {
  if (!userDetails) {
    return HAS_NOT_SUBSCRIBED_TO_PLAN;
  } else {
    if (userDetails?.isUserSubscribed) {
      return HAS_SUBSCRIBED_TO_PLAN;
    } else {
      return HAS_NOT_SUBSCRIBED_TO_PLAN;
    }
  }
};

export const checkUserHasFreePlan = (plans = []) => {
  let plan = LOADING;
  if (plans.length > 0) {
    const tempPlan = plans.filter((plan) => plan.chargebeeName === null)[0];
    if (!tempPlan?.hasUsedTrial) {
      plan = HAS_FREE_PLAN;
    } else {
      plan = HAS_NO_FREE_PLAN;
    }
  }
  return plan;
};

export const getFreeTrialDays = (plans = []) => {
  const plan = plans?.filter((plan) => plan?.chargebeeName === null)[0];
  return plan?.validity;
};

export const checkUserAddedPlanToCart = (cartDetails) => {
  let plan;
  let course;
  cartDetails?.cartItems?.forEach((item) => {
    if (item.itemable.__typename === "Plan") {
      plan = true;
    } else {
      course = true;
    }
  });
  if (plan && course) {
    return COURSE_AND_PLAN_ITEM;
  } else if (plan) {
    return PLAN_ITEM;
  } else if (course) {
    return COURSE_ITEM;
  }
};

export const UserUsingFreeTrial = (userDetails) => {
  if (
    userDetails?.getSubscriptionDetails === null &&
    userDetails?.isUserSubscribed
  ) {
    return true;
  } else {
    return false;
  }
};

export const skillCourseSubscriptionStatus = (
  hasSubscribed,
  hasFreePlan,
  isOnFreeTrial
) => {
  switch (hasSubscribed) {
    case HAS_SUBSCRIBED_TO_PLAN:
      if (isOnFreeTrial) {
        return "Your On Free Trial";
      }
      return isMobile() ? "Subscribed" : "You Have Subscribed To This Course !";
    case HAS_NOT_SUBSCRIBED_TO_PLAN:
      switch (hasFreePlan) {
        case HAS_FREE_PLAN:
          return "Get 150+ Courses @499/-";
        default:
          return "Get 150+ Courses @499/-";
      }
    default:
      break;
  }
};

export const sortPlansAcend = (plans = []) => {
  if (!isEmpty(plans)) {
    for (let i = 0; i <= plans.length - 2; i++) {
      for (let j = i + 1; j <= plans.length - 1; j++) {
        if (plans[i].validity > plans[j].validity) {
          const temp = plans[i];
          plans[i] = plans[j];
          plans[j] = temp;
        }
      }
    }
  }
  return plans;
};
